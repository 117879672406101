.news-slide{
    position: absolute;
    width: 22.5%;
    height: 22.5%;
    left: 75.5%;
    top: 73.5%;
    overflow: hidden;
    border-radius: 0.5vh;
    box-shadow: 0px 0px 4px 2px rgba(0,0,0,0.1);
}

.news-slide-dr{
    position: relative;
    box-sizing: border-box;
    background: rgba(255,255,255,0.3);
    color: black;
    padding: 1vh;
    height: 24.5vh;
}

.news-slide-dr h3{
    float: left;
    text-align: left;
    padding: 0;
    font-size: 1.8vh;
    line-height: 1.8vh;
    margin: 0 0 1vh;
    max-width: 65%; 
    overflow: hidden;
    text-overflow: ellipsis;
}

.news-slide-dr p{
    float: right;
    font-size: 1.6vh;
    line-height: 1.6vh;
    max-width: 48%;
    box-sizing: border-box;
    margin: 0;
    text-align: left;
    padding: 0.5vh 0 1vh 0;
}

.news-slide-dr p.full-width{
    max-width: 100%;
    padding: 0;
}

.news-slide-dr p a{
    text-decoration: none;
}

.news-slide-dr .article-img{
    max-width: 49%;
    max-height: 55%;
    float:left;
    padding-top: 0.5vh;
    padding-right: 1vh;
    bottom:1vh;

}

.news-slide-dr .logo {
    position: absolute;
    width: 35%;
    top: 0;
    right: 0;
    padding: 1vh;
    box-sizing: border-box;
}