@import url('https://fonts.googleapis.com/css?family=Sanchez|Fira+Sans');

html{
  /*background: url('../images/backdrop2.png');*/
  /*background: linear-gradient(-60deg,  rgb(121, 109, 155), rgb(190, 180, 210),rgb(121, 109, 155));*/
  /*background: linear-gradient(-30deg,  rgb(70, 8, 50), rgb(10, 10, 11),rgb(4, 4, 25));*/
  /*background: linear-gradient(-60deg,  rgb(110, 148, 101), rgb(180, 210, 187),rgb(110, 148, 101));*/
  /*background: linear-gradient(-40deg, rgb(147, 170, 78), rgb(194, 215, 129),rgb(129, 170, 41));
  background: linear-gradient(-20deg, rgb(85, 107, 24), rgb(255, 243, 105),rgb(78, 102, 17));
  background: linear-gradient(3deg, rgb(237, 135, 21), rgb(255, 246, 72),rgb(249, 145, 28));
  background: linear-gradient(3deg, rgb(70, 159, 127), rgb(135, 255, 230),rgb(65, 161, 154));*/
  background: linear-gradient(-70deg, rgb(0, 114, 143) 20%, rgb(3, 124, 155) 50%, rgb(0, 86, 107));
  /*background: linear-gradient(3deg, rgb(6, 0, 60), rgb(70, 144, 255),rgb(0, 106, 225));
  background: linear-gradient(3deg, rgb(0, 12, 80), rgb(80, 3, 255),rgb(222, 0, 255));*/
  background-size: cover;
  color: white;
  box-sizing: border-box;
}

body{
  font-family: 'Fira Sans', sans-serif;
  overflow: hidden;
  margin: 0;
}

h3{
  font-size: 2vh;
  line-height: 2vh;
}

p{
  font-size: 1vh;
  line-height: 1vh;
}

.App {
  text-align: center;
  width: 100vw;
  height: 100vh;
}

.content{
  float: left;
}

.slides{
  float: left;
  width: 95%;
  height: 33%;
}

.slides .temp-slide{
  box-sizing: border-box;
  height: 100%;
  width: 33.3%;
  padding: 2%;
  padding-right: 3%;
  padding-left: 3%;
  float: left;
}

.slides .temp-slide .slide{
  background: olive;
  width: 100%;
  height: 100%;
}

.slides .temp-slide .slide img{
  width: 100%;
}

.clearfix:after {
  content: "";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.clearfix {
  display: inline-block;
}

html[xmlns] .clearfix {
  display: block;
}

* html .clearfix {
  height: 1%;
}

.numInputWrapper span.arrowUp, .numInputWrapper span.arrowDown{
  opacity: 1;
  width: 40px;
}

.numInputWrapper span.arrowUp::after, .numInputWrapper span.arrowDown::after{
  left: 37%;
}

.flatpickr-time .numInputWrapper input{
  padding-right: 33px;
}

.slide-show{
  position: absolute;
  width: 22.5%;
  height: 22.5%;
  left: 52.7%;/*75.4%;*/
  top:73.5%;/*50.25%;*/
  overflow: hidden;
  border-radius: 0.5vh;
  box-shadow: 0px 0px 4px 2px rgba(0,0,0,0.1);
}

.slide-show .slide img{
  width: 100%;
}

/*Freetext*/

.bottom-wrapper {
  display:inline-block;
}

.free-text-wrapper {
  position: absolute;
  width: 22.5%;
  height: 22.5%;
  left: 29.9%;
  top:73.5%;
  overflow: hidden;
  border-radius: 0.5vh;
  box-shadow: 0px 0px 4px 2px rgba(0,0,0,0.1);
}


.free-text-item{
  margin-right: 1vw;
  width: 100%;
  height: 100%;

  background: rgba(255, 255, 255, 0.07);
  box-shadow: 0px 0px 4px 2px rgb(0 0 0 0.1);
  border-radius: 0.5vh;
}



.free-text{
  width: 100%;
  height: 100%;
}

.free-text .text{
  color: white;
  text-align: left;
  padding: 1vh;
  white-space: pre-wrap;
}

