.content{
    width: 95%;
    padding-right: 2%;
    box-sizing: border-box;
}
.calendar{
    position: relative;
    text-align: left;
    width: 100%;
}

.calendar .calendar-body{
    position: relative;
    width: 100%;
}

.calendar .calendar-body .hour-line{
    position: absolute;
    top: 0;
    width: 0.2vw;
    height: 100%;
    background: rgba(50,80,50,0.8);
}

.header{
}

.row{
    position: relative;
    line-height: 0;
    min-height: 1.1vh;
}

.row .plans{
    min-height: 1.1vh;
    overflow: hidden;
    position: absolute;
    width: 90%;
    left: 10%;
    top: 0;
}

.row .cell-grid{
    float: left;
    width: 90%;
}

.row .dd-cell.empty{
    overflow: hidden;
    border-top-left-radius: 0;
    border-bottom-right-radius: 0;
    background-color:  rgba(255, 255, 255, 0.1);
    box-shadow: initial;
    padding-right: 0.1vh;
    box-sizing: border-box;
}

.row:nth-child(even) .dd-cell.empty{
    background-color:  rgba(255, 255, 255, 0.05);
}

.row .plans .plan {
    box-sizing: border-box;
    position: absolute;
    padding: 0.1vh;
    margin: 0.1vh;
    height: 0.8vh;
    display: block;
    text-align: center;
    line-height: 1vh;
    font-size: 1vh;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 0 0 2px rgba(0,0,0,0.7);
}

.row .plans .plan.event{
    height: 0.5vh;
    top: 0.25vh;
    padding: 0;
    margin: 0;
    box-shadow: none;
}

.header-name{
    width: 10%;
    box-sizing: border-box;
    line-height: 4.6vh;
    height: 2.6vh;
    float: left;
    text-align: right;
    padding-right: 0.5%;
}

.row.c-14 .dd-day{
    font-size: 0.7vw;
}

.row.c-30 .dd-day{
    font-size: 0.7vw;
}

.row.c-90 .dd-day{
    font-size: 0.4vw;
}

.row.c-180 .dd-day{
    font-size: 0.2vw;
}


.dd-hour{
    text-align: center;
    box-sizing: border-box;
    line-height: 2.6vh;
    background: rgba(30,30,30,0.9);
    color: white;
    background-clip: content-box;
    padding: 0.1vh;
    display: inline-block;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.dd-day{
    text-align: center;
    box-sizing: border-box;
    height: 5vh;
    line-height: 2.25vh;
    background: rgba(30,30,30,0.9);
    color: white;
    background-clip: content-box;
    padding-right: 0.2vh;
    padding-bottom: 0.2vh;
    display: inline-block;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.dd-day.today{
    background-color:rgba(100,100,255,0.75);
}

.dd-day.weekend{
    background-color:rgba(195, 104, 255, 0.75);
}

.name{
    width: 10%;
    box-sizing: border-box;
    line-height: 0.95vh;
    font-size: 0.95vh;
    height: 0.95vh;
    float: left;
    text-align: right;
    padding-right: 0.5%;
    overflow: hidden;
}
.name.leader{
    color: rgb(50,50,50);
    font-weight: bold;
}

.dd-cell{
    text-align: center;
    box-sizing: border-box;
    line-height: 0.95vh;
    font-size: 0.95vh;
    height: 0.95vh;
    background-clip: content-box;

    display: inline-block;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 0 0 2px rgba(0,0,0,0.7);
}

.row .dd-cell.hightlight, .row:nth-child(even) .dd-cell.hightlight{
    background-color:  rgba(255, 255, 255, 0.6);
}

.dd-month{
    display: inline-block;
    text-align: center;
    box-sizing: border-box;
    /*background: rgba(50,50,50,0.9);*/
    background-clip: content-box;
    padding: 2px 2px;
    line-height: 3vh;
    height: 3vh;
    /*border-radius: 5px;*/
    border-bottom: 1px white solid;
    border-radius: 10px;
    font-size: 1.5vh;
}

.dd-month:first-of-type{
    padding-left: 0;
}

.dd-month:last-of-type{
    padding-right: 0;
}

.dd-months:nth-of-type(even){
    background: rgb(60,100,60);
}

.dd-months:nth-of-type(odd){
    background: rgb(20,100,20);
}

.dd-weeks:nth-of-type(even){
    background: rgb(20,20,100);
}
.dd-weeks:nth-of-type(odd){
    background: rgb(60,60,100);
}

.dd-week{
    text-align: center;
    box-sizing: border-box;
    line-height: 3vh;
    /*background: rgba(50,50,50,0.9);*/
    background-clip: content-box;
    padding: 2px 2px;
    height: 3vh;
    display: inline-block;
    /*border-radius: 5px;*/
    border-bottom: 1px white solid;
    margin-bottom: 5px;
    font-size: 1.2vh;
}

.dd-week:first-of-type{
    padding-left: 0;
}

.dd-week:last-of-type{
    padding-right: 0;
}

.calender-chooser{
    width: 100%;
    height: 40px;
    box-sizing: border-box;
}

.calender-chooser .react-datepicker-component .react-datepicker-input .button-wrapper .input-button{
    color: rgb(30,30,30);
}

.calender-chooser .react-datepicker-component .react-datepicker-input input{
    /*text-shadow: 1px 1px 2px black;*/
    color: rgb(30,30,30);
}

.calender-chooser .react-datepicker-component .react-datepicker-input{
    /*text-shadow: 1px 1px 2px black;*/
    color: rgb(30,30,30);
}

.datepicker-container{
    float: right;
}

.date-picker-custom{
    float: right;
    background: none;
    border: none;
    color: rgb(30,30,30);
    line-height: 36px;
    cursor: pointer;
    font-weight: bold;
    text-align: right;
    margin-right: 5px;
}
.datepicker-container:before{
    content: "";
    display: block;
    float: right;
    width: 11px;
    height: 36px;
    margin-right: 10px;
    background:url(data:image/gif;base64,R0lGODlhCwALAJEAAAAAAP///xUVFf///yH5BAEAAAMALAAAAAALAAsAAAIPnI+py+0/hJzz0IruwjsVADs=) no-repeat center right !important;
}

.period-pick{
    float: right;
    background: none;
    border: none;
    color: rgb(30,30,30);
    line-height: 36px;
    cursor: pointer;
    font-weight: bold;
    /*text-shadow: 1px 1px 2px black;*/
}

.period-pick.current{
    text-decoration: underline;
}

.period-pick:hover{
    color: lightseagreen;
}

.legend-container{
    position: fixed;
    bottom: 0;
    right: 2vw;
}

.legend{
    position: relative;
    padding: 2px;
    margin-left: 2vh;
    display: inline-block;
    list-style: none;
    font-weight: bold;
}

.legend p{
    color: white;
    margin: 0;
    font-weight: normal;
    font-size: 1.5vh;
    line-height: 2.3vh;
    text-shadow: 1px 1px 2px black;
}


.legend::before{
    content: "";
    border: 0.5vh solid;
    border-radius: 50%;
    position: absolute;
    left: -1vh;
    top: 0.9vh;
}