.App .static-map{
    width: 100%;
    height: 100%;
}
.App .static-map img{
    box-sizing: border-box;
    max-width: 47%;
    max-height: 95%;
    padding: 8% 1.5% 1.5%;
    float: left;
}