.team .members .unit{
    position: relative;
    width: 4.3vw;
    overflow: initial;
    float: initial;
    padding: 0.35vw;
    box-sizing: border-box;
    margin-bottom: 2.5vh;
    text-shadow: 1px 1px 2px black;
    display: inline-block;
}

.team .members .unit img{
    width: 100%;
    text-align: center;
    box-sizing: border-box;
    box-shadow: 0 0.5vh 1vh 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 1vh;
}

.team .members .unit .unit-description{
    position: absolute;
    text-align: center;
    left: -20%;
    right: -20%;
}
.team .members .unit .unit-description p{
    padding: 0;
    margin: 0;
}

.team.garden-team .members{
    padding-left: 5vw;
    margin-top: 0;
}

.team.garden-team .members .person{
    margin-bottom: 1.5vh;
}

.team.garden-team .team-description{
    display: none;
}
.garden-team.team h3.team-name{
    padding: 1vh;
}